import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { rhythm } from '../utils/typography'

const BioContainer = styled.div`
  display: flex;
  flex-direction: ${({ left }) => (left ? 'row' : 'column')};
  align-items: ${({ left }) => (left ? 'start' : 'center')};
  margin-bottom: ${rhythm(2.5)};
`

const Bio = ({ className, children, left }) => (
  <StaticQuery
    query={bioQuery}
    render={data => {
      const { author, social } = data.site.siteMetadata
      const description = `Written by <strong>${author}</strong> who lives in Lyon, France and he's passionate about Open Source and web development in general. Feel free to ping him on <a href="https://twitter.com/${
        social.twitter
      }" target="_blank" rel="noopener">Twitter</a>.`

      return (
        <BioContainer className={className} left={left}>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author}
            style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 0,
              minWidth: 50,
              borderRadius: `100%`,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: children ? children({ author }) : description,
            }}
          />
        </BioContainer>
      )
    }}
  />
)

Bio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func,
  left: PropTypes.bool,
}

Bio.defaultProps = {
  left: false,
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
